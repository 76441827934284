<template>
  <div>
    <div class="header">
      <div v-if="show" @click="close()" class="marsk"></div>
      <div class="welcome_top fs14 colf">欢迎光临</div>
      <div class="flex-box header_box">
        <img
          :src="logo"
          class="logo pointer"
          alt=""
           @click="goindex"
        />
        <div class="flex-grow-1">
          <div class="search_part flex-box">
            <input
              class="flex-grow-1 fs16 col9"
              placeholder="请输入您要搜索的内容"

              type="text"
              :value="keywords"

            />
            <img
              src="../assets/images/search_icon.png"
              class="search_icon pointer"
              alt=""
              @click="gosearchk"

            />
          </div>
          <div class="flex-box flex-center">
            <div
              class="fs12 col6 mr20 pointer plans"
              v-for="(item, index) in plans"
              @click="gosearch(item.name)"
              :key="index"
            >
              <template v-if="index==0">
                热门搜索:&nbsp;
              </template>
              {{ item.name}}
            </div>
          </div>
        </div>
        <div class="ml35 flex-box">
          <img
            class="message_icon pointer mr30"
            src="../assets/images/message_icon.png"
            alt=""
            @click="gonews"
          />
          <template v-if="!is_login" >

              <img
                  class="message_icon pointer mr5"
                  src="../assets/images/login_btn.png"
                  alt=""
                  @click="gologin"
              />
              <div class="fs14 col26 pointer plans pl5 pr5" @click="gologin">注册/登录</div>


          </template>
          <template v-else>
            <img
              @click="change()"
              class="message_icon pointer mr5"
              :src="userinfo.avatar"
              alt=""
            />
            <div
              @click="change()"
              class="fs14 col26 pointer plans pr pl5 pr5"
            >
              {{userinfo.nickname}}
              <div v-if="show" class="user_part fs14 col26 tc">

                <div class="user_name lh24 " @click="goorder">
                  我的订单
                </div>
                <div class="user_name lh24 " @click="gonews">
                  消息中心
                </div>
                <div class="user_name lh24 " @click="gohelps">
                  帮助中心
                </div>
                <div class="user_name lh24 " @click="goinfo">
                  个人信息
                </div>
                <div class="user_name lh24 " @click="gokefu">
                  联系客服
                </div>
              </div>
              
            </div>
            <img
              @click="change()"
              class="arrow_down_b pointer"
              src="../assets/images/arrow_down_b.png"
              alt=""
            />
          </template>
        </div>
      </div>
      <div class="tab flex-box">
        <div
          class="tablist pointer"
          v-for="(item, index) in tablist"
          :key="index"
          @click="testTabClick(index)"
          :class="[tabIndex == index ? 'active' : 'unactive']"
        >
          {{ item.name}}
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import cookie from "vue-cookies";
  import axios from "axios";
  import {apiGetCategoryDetail, apiGetConfig} from "../request/api";
export default {
  name: "headerName",
  data() {
    return {
      plans: [
     
      ],
      tablist: [{
        id:0,
        name:'首页',
        pid:0,
      }],
      tabIndex: 0,

      logo:'',
      show: false,
      is_login:false,
      userinfo:null,
      keywords:'',
      category_id:0
    };
  },

  created() {
    var logo = "logo1";
    this.logo = require("../assets/images/" + logo + ".png");

    var token = cookie.get("yxcz_token");
    if (!token) {
      this.is_login = false;
    } else {
      this.is_login = true;
      this.userinfo = cookie.get("yxcz_userinfo");
      if (!this.userinfo.avatar) {
        this.userinfo.avatar = avatar;
      }
    }

    if(this.$route.name=='Index'){
      this.tabIndex=0;
    }else{
      if(this.$route.name=='productList'){
        let params = this.$route.query;

        if (params.category_id) {
          this.category_id = params.category_id;

        }
        if(params.keywords){
          this.keywords=params.keywords;
        }
      }else{
        this.tabIndex=-1;
      }
    }
    this.get_config();
    window.addEventListener("popstate", this.popstate, false);
  },
  watch: {
    immediate: true, // 监听到路由的变化立即执行
    $route: {
      handler: function (val, oldVal) {
        console.log(val)
        let that = this;
        if (val.path == "/index") {

        }else if (val.path == "/category") {
          that.navIndex = 1;
        }
         else {

          that.navIndex = -1;
        }
        if (val.name == "index" && oldVal.name == "Info") {
          var token = cookie.get("yxcz_token");
          if (!token) {
            that.is_login = false;
          } else {
            that.is_login = true;
            that.userinfo = cookie.get("yxcz_userinfo");
            if (!that.userinfo.avatar) {
              that.userinfo.avatar = avatar;
            }
          }
        } else {
          var token = cookie.get("yxcz_token");
          if (!token) {
            that.is_login = false;
          } else {
            that.is_login = true;
            that.userinfo = cookie.get("yxcz_userinfo");
            if (!that.userinfo.avatar) {
              that.userinfo.avatar = avatar;
            }
          }
        }

      },
      // 深度观察监听
      deep: true,
    },
  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener("popstate", this.popstate, false);
  },
  // inject:['reload'],
  mounted() {
    // 不使用setTimeout无法在created和mounted中获取到this.$route的实际值
    setTimeout(() => {
      const curPath = this.$route.path;
      let that = this;
      if (curPath == "/index") {
        that.tabIndex = 0;
      }else if (curPath == "/category") {
        that.tabIndex = 1;
      }else{
        that.tabIndex=-1;
      }

      var token = cookie.get("yxcz_token");
      if (!token) {
        that.is_login = false;
      } else {
        that.is_login = true;
        that.userinfo = cookie.get("yxcz_userinfo");
        if (!that.userinfo.avatar) {
          that.userinfo.avatar = avatar;
        }
      }
    }, 1000);
  },
  methods: {
    close(){
this.show = false
    },
    getCategory(){
      var that =this,json={};
      json.id=that.category_id;
      apiGetCategoryDetail(json)
          .then((res) => {
            // 获取数据成功后的其他操作
            if(res.data){
              setTimeout(function () {

              })
            }
          })
          .catch((err) => {});
    },
    gosearchk(){
      this.$router.push({ path: "/productList?keywords="+this.keywords });
    },
    gosearch(name){
      this.keywords=name;
      this.$router.push({ path: "/productList?keywords="+name });
    },
    goindex(){
      this.$router.push({ path: "/" });
    },
    get_config() {
      var that =this;
      // 调用api接口，并且提供了两个参数
      // 调用api接口，并且提供了两个参数
      apiGetConfig({

      }).then(res => {
        // 获取数据成功后的其他操作
        that.tablist=res.data.categoryList?that.tablist.concat(res.data.categoryList):that.tablist;
        that.plans=res.data.keywords?res.data.keywords:[];
        console.log(that.category_id);
        console.log(that.tablist)
        for (let i=0;i<that.tablist.length;i++){

          if(that.category_id>0){

            if(that.category_id==that.tablist[i].id){
              that.tabIndex=i;

            }
          }
        }
        that.$forceUpdate()

      }).catch(err=>{

      })
    },
    gologin(){
      this.$router.push("/login");
    },
    change(){
				if(this.flag){
					this.show = false
				}else{
					this.show = true
				}
			},
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
      if(this.tabIndex==0){

        this.$router.push("/");
      }else{
        this.$router.push("/productList?category_id="+this.tablist[this.tabIndex].id);
      }

    },
    gokefu(){
      var that =this;

      if (!cookie.get("yxcz_userinfo")) {
        return that.$message.error("请先登录");
      }
      this.$router.push("/kefu");
    },
    goinfo(){
      var that =this;

      if (!cookie.get("yxcz_userinfo")) {
        return that.$message.error("请先登录");
      }
      this.$router.push("/mine");
    },
    gohelps()
    {
      var that =this;

      if (!cookie.get("yxcz_userinfo")) {
        return that.$message.error("请先登录");
      }
      this.$router.push("/mine");
    },
    gonews(){
      var that =this;

      if (!cookie.get("yxcz_userinfo")) {
        return that.$message.error("请先登录");
      }
      this.$router.push("/news");
    },
    goorder()
    {
      var that =this;

      if (!cookie.get("yxcz_userinfo")) {
        return that.$message.error("请先登录");
      }
      this.$router.push("/myOrder");
    }
 
  },
};
</script>
  
  <style scoped lang="less">
.welcome_top {
  width: 100%;
  height: 32px;
  line-height: 32px;
  background: #000000;
  padding-left: 109px;
}
.header_box {
  background: #f8f8f8;
  padding: 20px 120px 20px 109px;
  width: 100%;
}
.logo {
  width: 218px;
  height: 83px;
  display: block;
  object-fit: cover;
}
.search_part {
  width: 506px;
  height: 48px;
  line-height: 48px;
  border-radius: 28px;
  border: 1px solid #8f5afc;
  padding-left: 20px;
  margin: 0 auto 10px;
  .search_icon {
    width: 48px;
    height: 48px;
    display: block;
    object-fit: cover;
  }
}
.message_icon {
  width: 34px;
  height: 34px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.tab {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: linear-gradient(180deg, #b355de 0%, #8f5afc 100%);
  padding-left: 117px;
}
.tablist:hover,.active {
  padding: 0 39px 0 33px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(
    180deg,
    RGBA(185, 100, 224, 1) 0%,
    RGBA(165, 123, 251, 1)
  );
}
.unactive {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  padding: 0 30px;
}
.arrow_down_b {
  width: 6px;
  height: 4px;
  display: block;
  object-fit: cover;
}
.user_part {
  width: 100%;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 3;
  background: #fff;
  max-height: 120px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}
.marsk{
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background: transparent;
}
.user_name:hover {
  background: #bf6ef8;
  color: #fff;
}
</style>
  