<template>
  <div class="pagination">
    <a href="javascript:;" @click="pageTurn(0)" class="first_last font_hg">&lt;&lt;</a>
    <a href="javascript:;" @click="pageTurn(paging.index - 1)" class="first_last font_hg">&lt;</a>
    <template v-for="i in sum">
      <a href="javascript:;" @click="pageTurn(i - 1)" :key="i" v-if="showText(i)" v-text="showText(i)"
        :class="i === paging.index + 1 ? 'active' : 'page'" class="font_hg">
      </a>
    </template>
    <a href="javascript:;" @click="pageTurn(paging.index + 1)" class="first_last font_hg">&gt;</a>
    <a href="javascript:;" @click="pageTurn(sum - 1)" class="first_last font_hg">&gt;&gt;</a>
  </div>
</template>
<script>
export default {
  props: ["sumCount", "perCount"],
  data() {
    return {
      paging: {
        index: 0, // index
        count: 5, // count
      },
    };
  },
  computed: {
    sum() {
      // sum
      return Math.ceil(this.sumCount / this.perCount);
    },
  },
  methods: {
    // （）
    showText(i) {
      const vm = this;
      const index = vm.paging.index;
      const sum = vm.sum;
      if (i < 3 || i > sum - 2) {
        // 
        return i;
      } else if (i <= index + 2 && i >= index) {
        // 
        return i;
      } else if (i === index + 3 || i === index - 1) {
        //  ...
        return "...";
      } // 
      return false;
    },
    // 
    pageTurn(i) {
      const vm = this;
      if (i < 0 || i >= vm.sum || i === vm.paging.index) {
        return;
      }
      vm.paging.index = i;
      // ，init
      vm.$emit("pageTurn", i, vm.perCount);
    },
  },
};
</script>
<style scoped>
.pagination {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .first_last {
  line-height: 40px;
  height: 42px;
  width: 42px;
  text-align: center;
  border: 1px solid #bbb;
  color: #bbb;
  padding: 0 5px;
  margin: 0 5px;
}

.pagination .page {
  width: 42px;
  height: 42px;
  border: 1px solid #bbb;
  line-height: 40px;
  text-align: center;
  color: #bbb;
  margin: 0 5px;
}

.pagination a {
  border-radius: 4px;
}

.pagination a.active {
  width: 42px;
  height: 42px;
  border: 1px solid var(--lightgreen);
  line-height: 40px;
  text-align: center;
  color: var(--lightgreen);
  margin: 0 5px;
  background-color: var(--lightgreen);
}

.pagination a:hover,
.pagination a.active {
  color: #0B142B;
  background-color: var(--lightgreen);
  border-color: var(--lightgreen);
}

@media (max-width: 767px) {

  .pagination .first_last,
  .pagination .page,
  .pagination a.active {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
</style>
