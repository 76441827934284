import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import '../src/assets/css/style.css'
import './utils/rem'
import Header from '/src/components/Header.vue'
import Footer from '/src/components/footer.vue'
import pagination from '/src/components/pagination.vue'



Vue.config.productionTip = false;
// Vue.prototype.$api = api;//挂载
Vue.use(ElementUI);

new Vue({
  router,
  el: '#app',
  created() {
    document.title = '游戏充值'; // 设置网页标题
  },
  render: h => h(App)
}).$mount('#app');
// Vue.use(VueCookies)
Vue.component('Header',Header);
Vue.component('Footer',Footer);
Vue.component('pagination',pagination);
// Vue.component('popup',popup);
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title;
  }
})


