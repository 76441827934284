import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/index.vue"),
  },

  // // 5.我的订单
  {
    path: '/myOrder',
    name: 'myOrder',
    component: () => import( /* webpackChunkName: "about" */ '../views/myOrder.vue')
  },
  // // 4.确认订单
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    component: () => import( /* webpackChunkName: "about" */ '../views/confirmOrder.vue')
  },
  // // 4.商品列表
  { 
    path: '/productDetail',
    name: 'productDetail',
    component: () => import( /* webpackChunkName: "about" */ '../views/productDetail.vue')
  },
  // // 3.商品列表
  {
    path: '/productList',
    name: 'productList',
    component: () => import( /* webpackChunkName: "about" */ '../views/productList.vue')
  },
  // 2.首页
  {
    path: '/index',
    name: 'index',
    component: () => import( /* webpackChunkName: "about" */ '../views/index.vue')
  },
  // 1.登录
  {
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: "/helpCenter",
    name: "helpCenter",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/helpCenter.vue"),
  },
  {
    path: "/article" ,
    name: "article",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/article.vue"),
  },
  {
    path: "/category" ,
    name: "category",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/category.vue"),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import(/* webpackChunkName: "about" */ "../views/orderDetail.vue"),
  },
  {
    path: '/comments',
    name: 'comments',
    component: () => import(/* webpackChunkName: "about" */ "../views/comments.vue"),
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import(/* webpackChunkName: "about" */ "../views/evaluate.vue"),
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "about" */ "../views/mine.vue"),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue"),
  },
  {
    path: "/helps",
    name: "helps",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/helps.vue"),
  },
  {
    path: "/kefu",
    name: "kefu",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/kefu.vue"),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    return {
      y: 0,
    };
  },
});
let lastRoute = null;
// import VueCookies from 'vue-cookies';// token
router.beforeEach((to, from, next) => {
  // 在每次路由跳转前，记录上一个路由信息
  lastRoute = from;
  next();
});
console.log(lastRoute);
router.afterEach(() => {
  // 现在可以获取当前页面URL和上一页URL
  const currentUrl = router.currentRoute.path;
  const lastUrl = lastRoute && lastRoute.path;


  // 每次路由跳转后，重置lastRoute
  lastRoute = router.currentRoute;
});

export default router