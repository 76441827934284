/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import QS from 'qs';

import cookie from 'vue-cookies';
var token = cookie.get('yxcz_token');
import { Loading, Message } from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

// 环境的切换

if (process.env.NODE_ENV == 'development') {
    //axios.defaults.baseURL = 'http://www.rwfpc.com/index.php/api/';
    axios.defaults.baseURL = 'https://p.xilukeji.com/yxcz/public/index.php/api/';
} else if (process.env.NODE_ENV == 'production') {
   // axios.defaults.baseURL = 'http://www.rwfpc.com/index.php/api/';
     axios.defaults.baseURL = 'https://p.xilukeji.com/yxcz/public/index.php/api/';
}
//nrun baxios.defaults.baseURL = 'http://www.rwfpc.com/index.php/api/';
axios.defaults.withCredentials = false;  //允许跨域

// 请求超时时间
axios.defaults.timeout = 10000;

// get请求头
axios.defaults.headers.get['Yxcz-Token'] = token;// 登录标识
//axios.defaults.headers.get['Accept'] = 'application/json';// 格式
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers.post['Accept'] = 'application/json';// 格式
axios.defaults.headers.post['Yxcz-Token'] = token;// 登录标识
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // const token = store.state.token;
        // token && (config.headers.Authorization = token);
   //console.log(config.data);

        return config;
    },
    error => {

        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {

            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        console.log(error);
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    Message.error('请先登录');
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
                    Message.error('登录过期，请重新登录或权限操作');
                    // 清除token
                    localStorage.removeItem('yxc_token');
                    store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    // setTimeout(() => {
                    //     router.replace({
                    //         path: '/login',
                    //         query: {
                    //             redirect: router.currentRoute.fullPath
                    //         }
                    //     });
                    // }, 1000);
                    break;
                // 404请求不存在
                case 404:
                    Message.error('网络请求不存在');
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    Message.error(error.response.data.message || error.response.statusText);
            }
            return Promise.reject(error.response);
        }
    }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        },).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data);
        })
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            })
    });
}
export function post1(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            })
    });
}